<template>
  <v-tabs vertical>
    <v-tab>
      <v-icon left> mdi-book-multiple</v-icon>
      Бренды
    </v-tab>
    <v-tab>
      <v-icon left> mdi-help</v-icon>
      Вопросы
    </v-tab>
    <v-tab>
      <v-icon left> mdi-vector-combine</v-icon>
      Комбинации
    </v-tab>

    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <v-data-table :headers="brandsHeaders" :items="brands" class="elevation-1">
            <template v-slot:top>
              <v-toolbar flat>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="addBrandDialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openAddDialog('brand')">
                      Новый бренд
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-form ref="brand" v-model="valid" lazy-validation>
                          <v-row>
                            <v-text-field v-model="newBrand.name" label="Название" :rules="reqRules"></v-text-field>
                          </v-row>
                          <v-row>
                            <v-text-field v-model="newBrand.title" label="Заголовок" :rules="reqRules"></v-text-field>
                          </v-row>
                          <v-row>
                            <v-file-input v-model="newBrand.logo" label="Логотип" accept="image/*"
                                          :rules="reqRules"></v-file-input>
                            <div v-if="(typeof newBrand.logo) === 'string'">{{ newBrand.logo }}</div>
                          </v-row>
                          <v-row>
                            <v-text-field v-model="newBrand.slogan" label="Слоган" :rules="reqRules"></v-text-field>
                          </v-row>
                          <v-row>
                            <v-textarea v-model="newBrand.text" label="Описание" :rules="reqRules"></v-textarea>
                          </v-row>
                          <v-row>
                            <v-col>
                              <h2>Свойства</h2>
                            </v-col>
                            <v-col>
                              <v-btn @click="addIcon('prop')">Добавить</v-btn>
                            </v-col>
                          </v-row>
                          <template v-for="i in propsQuantity">
                            <v-container :key="'prop' + i">
                              <v-row>
                                <v-spacer></v-spacer>
                                <v-col cols="1">
                                  <v-btn icon @click="propsQuantity = propsQuantity-1; newBrand.icons.splice(i-1, 1)">
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="6">
                                  <v-text-field v-model="newBrand.icons[i-1].iconText" label="Свойство"
                                                :rules="reqRules"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-file-input v-model="newBrand.icons[i-1].iconPath" label="Иконка"
                                                accept="image/*" :rules="reqRules"></v-file-input>
                                  <div v-if="(typeof newBrand.icons[i-1].iconPath) === 'string'">
                                    {{ newBrand.icons[i - 1].iconPath }}
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </template>
                          <v-row>
                            <v-col>
                              <h2>Иконки</h2>
                            </v-col>
                            <v-col>
                              <v-btn @click="addIcon('icon')">Добавить</v-btn>
                            </v-col>
                          </v-row>
                          <template v-for="i in iconsQuantity">
                            <v-container :key="'icon' + i">
                              <v-row>
                                <v-col cols="11">
                                  <v-file-input v-model="newBrand.icons_top[i-1]" label="Иконка"
                                                accept="image/*" :rules="reqRules"></v-file-input>
                                  <div v-if="(typeof newBrand.icons_top[i-1]) === 'string'">
                                    {{ newBrand.icons_top[i - 1] }}
                                  </div>
                                </v-col>
                                <v-col cols="1">
                                  <v-btn icon
                                         @click="iconsQuantity = iconsQuantity-1; newBrand.icons_top.splice(i-1, 1)">
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-container>
                          </template>
                        </v-form>
                        <v-progress-linear v-if="loader" indeterminate color="primary"></v-progress-linear>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeForm"> Отмена</v-btn>
                      <v-btn color="blue darken-1" text @click="addBrand">Сохранить</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="deleteBrandDialog" max-width="420px">
                  <v-card>
                    <v-card-title class="text-h5">Вы уверены, что хотите удалить выбранный бренд?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="deleteBrandDialog=false">Отмена</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteBrand">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="openEditDialog(item, 'brand')">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteBrandDialog=true; current=item.id">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="loadBrands">Перезагрузить</v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <v-data-table :headers="quaHeaders" :items="questions" class="elevation-1"
                        show-expand>
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-dialog v-model="addQuaDialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openAddDialog('qua')">
                      Новый вопрос
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-form ref="question" v-model="valid" lazy-validation>
                          <v-row>
                            <v-col cols="6">
                              <v-text-field v-model="newQuestion.num" type="number" label="Номер вопроса"
                                            :rules="reqRules"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field v-model="newQuestion.title" label="Текст вопроса"
                                            :rules="reqRules"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field v-model="newQuestion.subtitle" label="Указание"
                                            :rules="reqRules"></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="6">
                              <v-text-field v-model="newQuestion.name" label="Название группы"
                                            :rules="reqRules"></v-text-field>
                            </v-col>
                            <v-col cols="6">
                              <v-autocomplete v-model="newQuestion.type" label="Тип вопроса" :rules="reqRules"
                                              :items="types"></v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="6">
                              <v-autocomplete v-model="newQuestion.dataOnlyFor" :items="branches" multiple
                                              label="Только для веток"></v-autocomplete>
                            </v-col>
                            <v-col cols="6">
                              <v-autocomplete v-model="newQuestion.dataNotFor" :items="branches" multiple
                                              label="Не для веток"></v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col>
                              <h2>Ответы</h2>
                            </v-col>
                            <v-col>
                              <v-btn @click="addAnswer">Добавить</v-btn>
                            </v-col>
                          </v-row>
                          <template v-for="i in answerQuantity">
                            <v-container :key="i">
                              <v-row>
                                <v-col cols="4">
                                  <h2>Ответ {{ i }}</h2>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="1">
                                  <v-btn icon @click="answerQuantity = answerQuantity-1; answers.splice(i-1, 1)">
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field v-model="answers[i-1].text" label="Текст"
                                                :rules="reqRules"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field v-model="answers[i-1].short_text" label="Короткий текст"
                                                :rules="reqRules"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="6">
                                  <v-text-field v-model="answers[i-1].val" label="Значение"
                                                :rules="reqRules"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-file-input v-model="answers[i-1].img" label="Изображение"
                                                prepend-icon="" accept="image/*" :rules="reqRules"></v-file-input>
                                  <div v-if="(typeof answers[i-1].img) === 'string'">{{ answers[i - 1].img }}</div>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="6">
                                  <v-text-field type="number" v-model="answers[i-1].toSkip"
                                                label="Пропуск"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                  <v-text-field v-model="answers[i-1].addBranch"
                                                label="Добавление ветки"></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </template>
                        </v-form>
                        <v-progress-linear v-if="loader" indeterminate color="primary"></v-progress-linear>
                      </v-container>
                      <v-alert border="right" colored-border type="error" elevation="2" v-model="errorAlert"
                               dismissible>
                        {{ errorMessage }}
                      </v-alert>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeForm"> Отмена</v-btn>
                      <v-btn color="blue darken-1" text @click="addQuestion">Сохранить</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="deleteQuaDialog" max-width="420px">
                  <v-card>
                    <v-card-title class="text-h5">Вы уверены, что хотите удалить выбранный вопрос?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="deleteQuaDialog=false">Отмена</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteQuestion">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="openEditDialog(item, 'qua')">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteQuaDialog=true; current=item.id">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table class="elevation-1">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th class="text-left">Текст</th>
                      <th class="text-left">Короткий текст</th>
                      <th class="text-left">Значение</th>
                      <th class="text-left">Изображение</th>
                      <th class="text-left">Пропуск</th>
                      <th class="text-left">Ветка</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(ans, i) in item.answers" :key="i">
                      <td>{{ ans.text }}</td>
                      <td>{{ ans.short_text }}</td>
                      <td>{{ ans.val }}</td>
                      <td>{{ ans.img }}</td>
                      <td>{{ ans.toSkip }}</td>
                      <td>{{ ans.addBranch }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="loadQuestions">Перезагрузить</v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-tab-item>
    <v-tab-item>
      <v-card flat>
        <v-card-text>
          <v-data-table :headers="combHeaders" :items="combinations" class="elevation-1"
                        show-expand>
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-dialog v-model="addCombDialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openAddDialog('comb')">
                      Новая комбинация
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-form ref="combinations" v-model="valid" lazy-validation>
                          <v-row>
                            <v-text-field v-model="newCombination.value" label="Значение"
                                          :rules="reqRules"></v-text-field>
                          </v-row>
                          <v-row>
                            <v-col>
                              <h2>Не равно</h2>
                            </v-col>
                            <v-col>
                              <v-btn @click="addToComb('no')">Добавить</v-btn>
                            </v-col>
                          </v-row>
                          <template v-for="i in newCombination.noQuantity">
                            <v-container :key="'no' + i">
                              <v-row>
                                <v-col cols="5">
                                  <v-text-field v-model="newCombination.no[i-1].name" label="Название"
                                                :rules="reqRules"></v-text-field>
                                </v-col>
                                <v-col cols="5">
                                  <v-text-field v-model="newCombination.no[i-1].val" label="Значение"
                                                :rules="reqRules"></v-text-field>
                                </v-col>
                                <v-col cols="1">
                                  <v-btn icon
                                         @click="newCombination.noQuantity = newCombination.noQuantity-1; newCombination.no.splice(i-1, 1)">
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-container>
                          </template>
                          <v-row>
                            <v-col>
                              <h2>Равно</h2>
                            </v-col>
                            <v-col>
                              <v-btn @click="addToComb('yes')">Добавить</v-btn>
                            </v-col>
                          </v-row>
                          <template v-for="i in newCombination.yesQuantity">
                            <v-container :key="'yes' + i">
                              <v-row>
                                <v-col cols="5">
                                  <v-text-field v-model="newCombination.yes[i-1].name" label="Название"
                                                :rules="reqRules"></v-text-field>
                                </v-col>
                                <v-col cols="5">
                                  <v-text-field v-model="newCombination.yes[i-1].val" label="Значение"
                                                :rules="reqRules"></v-text-field>
                                </v-col>
                                <v-col cols="1">
                                  <v-btn icon
                                         @click="newCombination.yesQuantity = newCombination.yesQuantity-1; newCombination.yes.splice(i-1, 1)">
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-container>
                          </template>
                          <v-row>
                            <v-col>
                              <h2>Больше</h2>
                            </v-col>
                            <v-col>
                              <v-btn @click="addToComb('more')">Добавить</v-btn>
                            </v-col>
                          </v-row>
                          <template v-for="i in newCombination.moreQuantity">
                            <v-container :key="'more' + i">
                              <v-row>
                                <v-col cols="5">
                                  <v-text-field v-model="newCombination.more[i-1].name" label="Название"
                                                :rules="reqRules"></v-text-field>
                                </v-col>
                                <v-col cols="5">
                                  <v-text-field v-model="newCombination.more[i-1].val" label="Значение"
                                                :rules="reqRules"></v-text-field>
                                </v-col>
                                <v-col cols="1">
                                  <v-btn icon
                                         @click="newCombination.moreQuantity = newCombination.moreQuantity-1; newCombination.more.splice(i-1, 1)">
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-container>
                          </template>
                          <v-row>
                            <v-col>
                              <h2>Меньше</h2>
                            </v-col>
                            <v-col>
                              <v-btn @click="addToComb('less')">Добавить</v-btn>
                            </v-col>
                          </v-row>
                          <template v-for="i in newCombination.lessQuantity">
                            <v-container :key="'less' + i">
                              <v-row>
                                <v-col cols="5">
                                  <v-text-field v-model="newCombination.less[i-1].name" label="Название"
                                                :rules="reqRules"></v-text-field>
                                </v-col>
                                <v-col cols="5">
                                  <v-text-field v-model="newCombination.less[i-1].val" label="Значение"
                                                :rules="reqRules"></v-text-field>
                                </v-col>
                                <v-col cols="1">
                                  <v-btn icon
                                         @click="newCombination.lessQuantity = newCombination.lessQuantity-1; newCombination.less.splice(i-1, 1)">
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-container>
                          </template>
                        </v-form>
                        <v-progress-linear v-if="loader" indeterminate color="primary"></v-progress-linear>
                      </v-container>
                      <v-alert border="right" colored-border type="error" elevation="2" v-model="errorAlert"
                               dismissible>
                        {{ errorMessage }}
                      </v-alert>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeForm"> Отмена</v-btn>
                      <v-btn color="blue darken-1" text @click="addCombination">Сохранить</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="deleteCombDialog" max-width="420px">
                  <v-card>
                    <v-card-title class="text-h5">Вы уверены, что хотите удалить выбранную комбинацию?</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="deleteCombDialog=false">Отмена</v-btn>
                      <v-btn color="blue darken-1" text @click="deleteCombination">OK</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small @click="openEditDialog(item, 'comb')">
                mdi-pencil
              </v-icon>
              <v-icon small @click="deleteCombDialog=true; current=item.id">
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-simple-table v-if="item.no.length > 0" dense>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <h3>Нет</h3>
                    </tr>
                    <tr>
                      <th class="text-left"> Параметр</th>
                      <th class="text-left"> Значение</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(val, i) in item.no" :key="i">
                      <td>{{ val.name }}</td>
                      <td>{{ val.val }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-simple-table v-if="item.yes.length > 0" dense>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <h3>Да</h3>
                    </tr>
                    <tr>
                      <th class="text-left"> Параметр</th>
                      <th class="text-left"> Значение</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(val, i) in item.yes" :key="i">
                      <td>{{ val.name }}</td>
                      <td>{{ val.val }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-simple-table v-if="item.more.length > 0" dense>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <h3>Больше</h3>
                    </tr>
                    <tr>
                      <th class="text-left"> Параметр</th>
                      <th class="text-left"> Значение</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(val, i) in item.more" :key="i">
                      <td>{{ val.name }}</td>
                      <td>{{ val.val }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-simple-table v-if="item.less.length > 0" dense>
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <h3>Меньше</h3>
                    </tr>
                    <tr>
                      <th class="text-left"> Параметр</th>
                      <th class="text-left"> Значение</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(val, i) in item.less" :key="i">
                      <td>{{ val.name }}</td>
                      <td>{{ val.val }}</td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </td>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="loadCombinations">Перезагрузить</v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import $ from 'jquery'

export default {
  name: "Home",
  data() {
    return {
      brands: [],
      questions: [],
      combinations: [],
      branches: [],
      brandsHeaders: [
        {text: 'Название', align: 'start', value: 'name',},
        {text: 'Заголовок', align: 'start', value: 'title',},
        {text: 'Логотип', align: 'start', value: 'logo',},
        {text: 'Слоган', align: 'start', value: 'slogan',},
        {text: 'Свойства', value: 'icons'},
        {text: 'Описание', value: 'text'},
        {text: 'Действия', value: 'actions', sortable: false},
      ],
      quaHeaders: [
        {text: 'Номер', align: 'start', value: 'num',},
        {text: 'Вопрос', align: 'start', value: 'title',},
        {text: 'Указание', align: 'start', value: 'subtitle',},
        {text: 'Название', value: 'name'},
        {text: 'Тип', value: 'type'},
        {text: 'Только для', value: 'dataOnlyFor'},
        {text: 'Не для', value: 'dataNotFor'},
        {text: 'Действия', value: 'actions', sortable: false},
      ],
      combHeaders: [
        {text: 'Значение', align: 'start', value: 'value',},
        {text: 'Действия', value: 'actions', sortable: false},
      ],
      current: 0,
      newBrand: {
        name: '',
        title: '',
        logo: null,
        slogan: '',
        icons: [],
        icons_top: [],
        text: '',
      },
      newQuestion: {
        num: '',
        title: '',
        subtitle: '',
        name: '',
        type: '',
        dataOnlyFor: '',
        dataNotFor: '',
      },
      newCombination: {
        value: '',
        noQuantity: 0,
        yesQuantity: 0,
        lessQuantity: 0,
        moreQuantity: 0,
        no: [],
        yes: [],
        more: [],
        less: []
      },
      answerQuantity: 0,
      iconsQuantity: 0,
      propsQuantity: 0,
      answers: [],
      types: ['checkbox', 'radio', 'range', 'slider'],
      formTitle: '',
      addBrandDialog: false,
      addQuaDialog: false,
      addCombDialog: false,
      deleteBrandDialog: false,
      deleteQuaDialog: false,
      deleteCombDialog: false,
      loader: false,
      valid: true,
      errorMessage: '',
      errorAlert: false,
      reqRules: [
        v => !!v || 'Необходимо заполнить поле'
      ],
    }
  },
  created() {
    this.loadBrands()
    this.loadQuestions()
    this.loadCombinations()
    this.loadBranches()
  },
  methods: {
    loadBrands() {
      $.ajax({
        type: "GET",
        url: "https://crizal-back.ffox.site/getAll",
        success: (msg) => {
          this.brands = msg
        },
        error: function (msg) {
          console.log(msg)
        }
      })
    },
    loadQuestions() {
      $.ajax({
        type: "GET",
        url: "https://crizal-back.ffox.site/allQuestion",
        success: (msg) => {
          this.questions = msg
        },
        error: function (msg) {
          console.log(msg)
        }
      })
    },
    loadCombinations() {
      $.ajax({
        type: "GET",
        url: "https://crizal-back.ffox.site/allCombinations",
        success: (msg) => {
          this.combinations = msg
        },
        error: function (msg) {
          console.log(msg)
        }
      })
    },
    loadBranches() {
      $.ajax({
        type: "GET",
        url: "https://crizal-back.ffox.site/getBranches",
        success: (msg) => {
          this.branches = msg
        },
        error: function (msg) {
          console.log(msg)
        }
      })
    },
    addBrand() {
      if (this.$refs.brand.validate()) {
        if (this.current !== 0) {
          this.putBrand()
        } else {
          this.loader = true
          this.newBrand.icons.forEach(icon => {
            var formData = new FormData();
            formData.append("photo", icon.iconPath, "photo.png")

            $.ajax({
              url: 'https://crizal-back.ffox.site/uploadImg',
              type: "post",
              data: formData,
              async: false,
              success: (path) => {
                icon.iconPath = path
              },
              error: (msg) => {
                this.loader = false
                console.log(msg)
                alert('Ошибка загрузки изображения!');
              },
              cache: false,
              contentType: false,
              processData: false
            })
          })
          var formData = new FormData();
          for (let i = 0; i < this.iconsQuantity; i++) {
            if ((typeof this.newBrand.icons_top[i]) !== 'string') {
              formData = new FormData()
              formData.append("photo", this.newBrand.icons_top[i], "photo.png")

              $.ajax({
                url: 'https://crizal-back.ffox.site/uploadImg',
                type: "post",
                data: formData,
                async: false,
                success: (path) => {
                  this.newBrand.icons_top[i] = path
                },
                error: (msg) => {
                  this.loader = false
                  console.log(msg)
                  alert('Ошибка загрузки изображения!');
                },
                cache: false,
                contentType: false,
                processData: false
              })
            }
          }
          formData = new FormData()
          formData.append("photo", this.newBrand.logo, "photo.png")
          $.ajax({
            url: 'https://crizal-back.ffox.site/uploadImg',
            type: "post",
            data: formData,
            async: false,
            success: (path) => {
              this.newBrand.logo = path
            },
            error: (msg) => {
              this.loader = false
              console.log(msg)
              alert('Ошибка загрузки изображения!');
            },
            cache: false,
            contentType: false,
            processData: false
          })
          $.ajax({
            type: "GET",
            data: {
              name: this.newBrand.name,
              title: this.newBrand.title,
              logo: this.newBrand.logo,
              slogan: this.newBrand.slogan,
              icons: JSON.stringify(this.newBrand.icons),
              icons_top: JSON.stringify(this.newBrand.icons_top),
              text: this.newBrand.text,
            },
            url: "https://crizal-back.ffox.site/addBrand",
            success: () => {
              this.loader = false
              this.closeForm()
              this.loadBrands()
            },
            error: function (msg) {
              console.log(msg)
              this.loader = false
              this.errorMessage = "Непредвиденная ошибка сервера"
              this.errorAlert = true
            }
          })
        }
      } else {
        this.valid = false
        this.errorMessage = "Заполните необходимые поля"
        this.errorAlert = true
      }
    },
    putBrand() {
      this.loader = true
      this.newBrand.icons.forEach(icon => {
        if ((typeof icon.iconPath) !== 'string') {
          var formData = new FormData();
          formData.append("photo", icon.iconPath, "photo.png")

          $.ajax({
            url: 'https://crizal-back.ffox.site/uploadImg',
            type: "post",
            data: formData,
            async: false,
            success: (path) => {
              icon.iconPath = path
            },
            error: (msg) => {
              this.loader = false
              console.log(msg)
              alert('Ошибка загрузки изображения!');
            },
            cache: false,
            contentType: false,
            processData: false
          })
        }
      })
      var formData = new FormData();
      for (let i = 0; i < this.iconsQuantity; i++) {
        if ((typeof this.newBrand.icons_top[i]) !== 'string') {
          formData = new FormData()
          formData.append("photo", this.newBrand.icons_top[i], "photo.png")

          $.ajax({
            url: 'https://crizal-back.ffox.site/uploadImg',
            type: "post",
            data: formData,
            async: false,
            success: (path) => {
              console.log(path)
              this.newBrand.icons_top[i] = path
            },
            error: (msg) => {
              this.loader = false
              console.log(msg)
              alert('Ошибка загрузки изображения!');
            },
            cache: false,
            contentType: false,
            processData: false
          })
        }
      }
      if ((typeof this.newBrand.logo) !== 'string') {
        formData = new FormData()
        formData.append("photo", this.newBrand.logo, "photo.png")
        $.ajax({
          url: 'https://crizal-back.ffox.site/uploadImg',
          type: "post",
          data: formData,
          async: false,
          success: (path) => {
            this.newBrand.logo = path
          },
          error: (msg) => {
            this.loader = false
            console.log(msg)
            alert('Ошибка загрузки изображения!');
          },
          cache: false,
          contentType: false,
          processData: false
        })
      }
      $.ajax({
        type: "GET",
        data: {
          id: this.newBrand.id,
          name: this.newBrand.name,
          title: this.newBrand.title,
          logo: this.newBrand.logo,
          slogan: this.newBrand.slogan,
          icons: JSON.stringify(this.newBrand.icons),
          icons_top: JSON.stringify(this.newBrand.icons_top),
          text: this.newBrand.text,
        },
        url: "https://crizal-back.ffox.site/updateBrand",
        success: () => {
          this.loader = false
          this.closeForm()
          this.loadBrands()
        },
        error: function (msg) {
          console.log(msg)
          this.loader = false
          this.errorMessage = "Непредвиденная ошибка сервера"
          this.errorAlert = true
        }
      })
    },
    deleteBrand() {
      $.ajax({
        type: "GET",
        data: {
          id: this.current
        },
        url: "https://crizal-back.ffox.site/delete",
        success: () => {
          this.deleteBrandDialog = false
          this.current = 0
          this.loadBrands()
        },
        error: function (msg) {
          console.log(msg)
        }
      })
    },
    addQuestion() {
      if (this.current !== 0)
        this.putQuestion()
      else if (this.$refs.question.validate()) {
        this.loader = true
        let id = -1;
        if (this.newQuestion.dataNotFor != null)
          this.newQuestion.dataNotFor = this.newQuestion.dataNotFor.toString()
        if (this.newQuestion.dataOnlyFor != null)
          this.newQuestion.dataOnlyFor = this.newQuestion.dataOnlyFor.toString()
        $.ajax({
          type: "get",
          data: this.newQuestion,
          url: "https://crizal-back.ffox.site/addQuestion",
          success: (msg) => {
            id = msg;

            if (this.answers.length !== 0)
              this.answers.forEach(answer => {
                var formData = new FormData();
                formData.append("photo", answer.img, "photo.png")
                answer.question_id = id

                $.ajax({
                  url: 'https://crizal-back.ffox.site/uploadImg',
                  type: "post",
                  data: formData,
                  async: false,
                  success: (path) => {
                    answer.img = path

                    this.postAnswer(answer)
                  },
                  error: (msg) => {
                    this.loader = false
                    console.log(msg)
                    alert('Ошибка загрузки изображения!');
                  },
                  cache: false,
                  contentType: false,
                  processData: false
                });
              })
            else {
              this.loader = false
              this.closeForm()
            }
          },
          error: (msg) => {
            console.log(msg)
            this.loader = false
            this.errorMessage = "Непредвиденная ошибка сервера"
            this.errorAlert = true
          }
        })
      } else {
        this.valid = false
        this.errorMessage = "Заполните необходимые поля"
        this.errorAlert = true
      }
    },
    postAnswer(answer) {
      $.ajax({
        method: "get",
        data: answer,
        url: "https://crizal-back.ffox.site/addAnswers",
        success: () => {
          this.loadQuestions()
          this.loader = false
          this.closeForm()
        },
        error: (msg) => {
          this.loader = false
          console.log(msg)
        }
      })
    },
    putQuestion() {
      if (this.$refs.question.validate()) {
        this.loader = true
        if (Array.isArray(this.newQuestion.dataNotFor))
          this.newQuestion.dataNotFor = this.newQuestion.dataNotFor.toString()
        if (Array.isArray(this.newQuestion.dataOnlyFor))
          this.newQuestion.dataOnlyFor = this.newQuestion.dataOnlyFor.toString()
        delete this.newQuestion.answers
        $.ajax({
          type: "get",
          data: this.newQuestion,
          url: "https://crizal-back.ffox.site/updateQuestion",
          success: () => {
            if (this.answers.length !== 0) {
              this.answers.forEach(answer => {
                if ((typeof answer.img) !== 'string') {
                  var formData = new FormData();
                  formData.append("photo", answer.img, "photo.png")

                  $.ajax({
                    url: 'https://crizal-back.ffox.site/uploadImg',
                    type: "post",
                    data: formData,
                    async: false,
                    success: (path) => {
                      answer.img = path

                      if (answer.id === undefined) {
                        answer.question_id = this.newQuestion.id
                        this.postAnswer(answer)
                      } else
                        $.ajax({
                          method: "get",
                          data: answer,
                          url: "https://crizal-back.ffox.site/updateAnswers",
                          success: () => {
                            this.loadQuestions()
                            this.loader = false
                            this.closeForm()
                          },
                          error: (msg) => {
                            this.loader = false
                            console.log(msg)
                          }
                        })
                    },
                    error: (msg) => {
                      this.loader = false
                      console.log(msg)
                      alert('Ошибка загрузки изображения!');
                    },
                    cache: false,
                    contentType: false,
                    processData: false
                  });
                } else {
                  $.ajax({
                    method: "get",
                    data: answer,
                    url: "https://crizal-back.ffox.site/updateAnswers",
                    success: () => {
                      this.loadQuestions()
                      this.loader = false
                      this.closeForm()
                    },
                    error: (msg) => {
                      this.loader = false
                      console.log(msg)
                    }
                  })
                }
              })
            } else {
              this.loader = false
              this.closeForm()
            }
          },
          error: (msg) => {
            console.log(msg)
            this.loader = false
            this.errorMessage = "Непредвиденная ошибка сервера"
            this.errorAlert = true
          }
        })
      } else {
        this.valid = false
        this.errorMessage = "Заполните необходимые поля"
        this.errorAlert = true
      }
    },
    deleteQuestion() {
      $.ajax({
        type: "GET",
        data: {
          id: this.current
        },
        url: "https://crizal-back.ffox.site/deleteQuestion",
        success: () => {
          this.deleteQuaDialog = false
          this.current = 0
          this.loadQuestions()
        },
        error: function (msg) {
          console.log(msg)
        }
      })
    },
    addCombination() {
      if (this.$refs.combinations.validate()) {
        if (this.current !== 0) {
          this.putCombination()
        } else {
          $.ajax({
            type: "GET",
            data: {
              value: this.newCombination.value,
              no: JSON.stringify(this.newCombination.no),
              yes: JSON.stringify(this.newCombination.yes),
              more: JSON.stringify(this.newCombination.more),
              less: JSON.stringify(this.newCombination.less)
            },
            url: "https://crizal-back.ffox.site/addCombinations",
            success: () => {
              this.closeForm()
              this.loadCombinations()
            },
            error: function (msg) {
              console.log(msg)
            }
          })
        }
      } else {
        this.valid = false
        this.errorMessage = "Заполните необходимые поля"
        this.errorAlert = true
      }
    },
    putCombination() {
      $.ajax({
        url: "https://crizal-back.ffox.site/updateCombinations",
        type: "GET",
        data: {
          id: this.newCombination.id,
          value: this.newCombination.value,
          no: JSON.stringify(this.newCombination.no),
          yes: JSON.stringify(this.newCombination.yes),
          more: JSON.stringify(this.newCombination.more),
          less: JSON.stringify(this.newCombination.less)
        },
        success: () => {
          this.closeForm()
          this.loadCombinations()
        },
        error: function (msg) {
          console.log(msg)
        }
      })
    },
    deleteCombination() {
      $.ajax({
        type: "GET",
        data: {
          id: this.current
        },
        url: "https://crizal-back.ffox.site/deleteCombinations",
        success: () => {
          this.deleteCombDialog = false
          this.current = 0
          this.loadCombinations()
        },
        error: function (msg) {
          console.log(msg)
        }
      })
    },
    addIcon(type) {
      if (type === 'prop') {
        this.newBrand.icons.push({iconText: '', iconPath: null})
        this.propsQuantity = this.propsQuantity + 1
      } else if (type === 'icon') {
        this.newBrand.icons_top.push('null')
        this.iconsQuantity = this.iconsQuantity + 1
        // this.newBrand.icons_top = JSON.stringify(this.newBrand.icons_top)
        // this.newBrand.icons_top = JSON.parse(this.newBrand.icons_top)
      }
    },
    addAnswer() {
      this.answers.push({text: '', short_text: '', val: '', img: null, toSkip: 0, addBranch: ''})
      this.answerQuantity = this.answerQuantity + 1
    },
    addToComb(item) {
      this.newCombination[item].push({name: '', val: ''})
      this.newCombination[item + 'Quantity'] = this.newCombination[item + 'Quantity'] + 1
    },
    openAddDialog(type) {
      this.current = 0
      switch (type) {
        case 'brand':
          this.formTitle = 'Добавление бренда'
          this.addBrandDialog = true
          break
        case 'qua':
          this.formTitle = 'Добавление вопроса'
          this.addQuaDialog = true
          break
        case 'comb':
          this.formTitle = 'Добавление комбинации'
          this.addCombDialog = true
          break
      }
    },
    openEditDialog(item, type) {
      this.current = item.id
      switch (type) {
        case 'brand':
          this.formTitle = 'Редактирование бренда'
          this.addBrandDialog = true
          this.newBrand = item
          this.newBrand.icons = JSON.parse(this.newBrand.icons)
          this.propsQuantity = this.newBrand.icons.length
          this.newBrand.icons_top = JSON.parse(this.newBrand.icons_top)
          this.iconsQuantity = this.newBrand.icons_top.length
          break
        case 'qua':
          this.formTitle = 'Редактирование вопроса'
          this.addQuaDialog = true
          this.newQuestion = item
          this.answerQuantity = item.answers.length
          this.answers = item.answers
          break
        case 'comb':
          this.formTitle = 'Редактирование комбинации'
          this.addCombDialog = true
          this.newCombination = item
          this.newCombination.noQuantity = this.newCombination.no.length
          this.newCombination.yesQuantity = this.newCombination.yes.length
          this.newCombination.moreQuantity = this.newCombination.more.length
          this.newCombination.lessQuantity = this.newCombination.less.length
          break
      }
    },
    closeForm() {
      this.addBrandDialog = false
      this.addQuaDialog = false
      this.addCombDialog = false
      this.errorAlert = false
      this.loader = false
      this.valid = true
      this.iconsQuantity = 0
      this.propsQuantity = 0
      this.answerQuantity = 0
      this.answers = []
      this.newBrand = {
        name: '',
        title: '',
        logo: null,
        slogan: '',
        icons: [],
        icons_top: [],
        text: '',
      }
      this.newQuestion = {
        num: '',
        title: '',
        subtitle: '',
        name: '',
        type: '',
        dataOnlyFor: '',
        dataNotFor: '',
      }
      this.newCombination = {
        value: '',
        noQuantity: 0,
        yesQuantity: 0,
        lessQuantity: 0,
        moreQuantity: 0,
        no: [],
        yes: [],
        more: [],
        less: []
      }
    }
  }
}
</script>

<style scoped>

</style>